import React from "react";
import "./../Mergallcamponents/Mergallcamponents.css";
import logo_synergy from "../../Assets/logo-synergytech.png";
import Sellyourhouse from "./../Sellyourhouse/Sellyourhouse";
import Sellingproperty from "./../Sellingproperty/Sellingproperty";
import Workprocess from "./../Workprocess/Workprocess";
import Easytosale from "./../Easytosale/Easytosale";
import Trustedoffer from "./../Trustedoffer/Trustedoffer";
import Clientsfeedback from "./../Clientsfeedback/Clientsfeedback";
import Clientscards from "./../Clientscards/Clientscards";
import Pinlocations from "./../Pinlocations/Pinlocations";
import Inputinformation from "./../Inputinformation/Inputinformation";
function Mergallcamponents() {
  return (
    <>
      <nav class="navbar navbar-expand-lg main_div_navbar ">
        <div class="container-fluid ">
          <img src={logo_synergy} className="logo_style" />
          <button
            class="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav me-auto mb-2 mb-lg-0">
              <li class="nav-item"></li>
            </ul>
            
            <form class="d-flex" role="search">
              <a className="navbar_para" href="#sellyouhouse">
                Sell Your House
              </a>
              <a className="navbar_para" href="#howuitwork">How it Works</a>
              <a className="navbar_para" href="#ourcompany">Our Company</a>
              <a className="navbar_para" href="#review">Reviews</a>
              <a className="navbar_para" href="#faq">FAQ</a>
              <a className="navbar_para" href="#contactus">Contact Us</a>
            </form>
          </div>
        </div>
      </nav>

      <>
        <div id="sellyouhouse">
          <Sellyourhouse />
        </div>

        <div id="howuitwork">
          <Sellingproperty />
        </div>

        <div>
          <Workprocess />
        </div>

        <div id="faq">
          <Easytosale />
        </div>

        <div id="ourcompany">
          <Trustedoffer />
        </div>

        <div id="review">
          <Clientsfeedback />
        </div>
        <div>
          <Clientscards />
        </div>

        <div>
          <Pinlocations />
        </div>
        <div id="contactus">
          <Inputinformation />
        </div>
      </>
    </>
  );
}

export default Mergallcamponents;

import React from 'react'
import "./../Trustedoffer/Trustedoffer.css";
import doller from "../../Assets/Doller-img.png";
function Trustedoffer() {
  return (
    <div>
       <div className="background_style3 mb-5">
    
    <div>
      <p className="trusted_heading">
        Get A Trusted Offer For Your Broward County Home!
      </p>
      <p className="cash_offer">
        We Offer Cash Advacne, Options & Flexibility Based On YOUR Needs!
      </p>
    </div>
    <div>
    <img src={doller}  alt="logo_synergy" className="doller_img" />
    
    </div>
  </div>
    </div>
  )
}

export default Trustedoffer

import React from "react";
import "./../Sellingproperty/Sellingproperty.css";
function Sellingproperty() {
  return (
    <div className="section_style">
      <div className="container mb-4">
        <div className="property_heading mb-3">
          What are the differences between selling your house with a realtor and
          selling to Property Nation?
        </div>
        <div className="selling_heading">
          Selling a House by Owner vs. Realtor – A Comparison Chart
        </div>
        <div className="row">
          <div className="col-lg-4 col-md-4 col-12 ">
            <div className="yellow_option mt-5 ">
              <p className="input_proparty">Comparing</p>
            </div>

            <div className="input_options">
              <div className="points_yellow mt-1">
                <div className="input_name">Commissions / Fees</div>
              </div>
            </div>

            <div className="input_options">
              <div className="points_yellow mt-1">
                <div className="input_name">Financing Contingency*</div>
              </div>
            </div>

            <div className="input_options">
              <div className="points_yellow mt-1">
                <div className="input_name">Appraisal Needed</div>
              </div>
            </div>
            <div className="input_options">
              <div className="points_yellow mt-1">
                <div className="input_name">Average Days Until Sold</div>
              </div>
            </div>
            <div className="input_options">
              <div className="points_yellow mt-1">
                <div className="input_name">Number of Showings</div>
              </div>
            </div>
            <div className="input_options">
              <div className="points_yellow mt-1">
                <div className="input_name">Closing Date</div>
              </div>
            </div>
            <div className="input_options">
              <div className="points_yellow mt-1">
                <div className="input_name">Who Pays For Repairs?</div>
              </div>
            </div>
            <div className="input_options">
              <div className="points_yellow mt-1">
                <div className="input_name">Comparing</div>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-4 col-12 ">
            <div className="green_option mt-5 ">
              <p className="input_green">Property Nation</p>
            </div>
            <div className="input_options1">
              <p className="input_name1">None</p>
            </div>

            <div className="input_options1">
              <p className="input_name1">None – We pay all costs</p>
            </div>

            <div className="input_options1">
              <p className="input_name1">None</p>
            </div>
            <div className="input_options1">
              <p className="input_name1">None – We make cash offers</p>
            </div>
            <div className="input_options1">
              <p className="input_name1">21 Days</p>
            </div>
            <div className="input_options1">
              <p className="input_name1">1 (Just Us)</p>
            </div>
            <div className="input_options1">
              <p className="input_name1">ASAP or The Date Of YOUR CHOICE</p>
            </div>
            <div className="input_options1">
              <p className="input_name1">None – We buy in “AS-IS” Condition</p>
            </div>
          </div>

          <div className="col-lg-4 col-md-4 col-12 ">
            <div className="blue_option mt-5">
              <p className="input_blue">Selling with Realtor</p>
            </div>
            <div className="input_options1">
              <p className="input_name1">
                6% on average is paid by you, the seller
              </p>
            </div>

            <div className="input_options1">
              <p className="input_name1">
                2% on average is paid by you, the seller
              </p>
            </div>
            <div className="input_options1">
              <p className="input_name1">
                Yes, up to 15% of sales fall through
              </p>
            </div>
            <div className="input_options1">
              <p className="input_name1">
                Yes, sale is often subject to appraisal
              </p>
            </div>
            <div className="input_options1">
              <p className="input_name1">98 Days</p>
            </div>
            <div className="input_options1">
              <p className="input_name1">Multiple</p>
            </div>
            <div className="input_options1">
              <p className="input_name1">
                0-60 +/- days after accepting buyers offer
              </p>
            </div>

            <div className="input_options1">
              <p className="input_name1">Negotiated During Inspection Period</p>
            </div>
          </div>
        </div>

        <div className="offer_button_box">
            <p className="input_offer_button">
            GET MY CASH OFFER NOW
            </p>
        </div>
      </div>
    </div>
  );
}

export default Sellingproperty;

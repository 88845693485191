import React from 'react'
import "./../Pinlocations/Pinlocations.css";
import location from "../../Assets/location-points.png";
function Pinlocations() {
  return (
    <div>
       <div className="location_photo">
      <div className="container">
        <div className="buy_houses">
          We Buy Houses All Over Florida in areas like...
        </div>
        <div className="row">
          <div className="col-lg-1 col-md-1 col-1 mt-3">
            <img
              src={location}
              alt="location-points.png"
              className="location_points_img "
            />
            <img
              src={location}
              alt="location-points.png"
              className="location_points2_img"
            />
            <img
              src={location}
              alt="location-points.png"
              className="location_points2_img"
            />
            <img
              src={location}
              alt="location-points.png"
              className="location_points2_img"
            />
            <img
              src={location}
              alt="location-points.png"
              className="location_points2_img"
            />
            <img
              src={location}
              alt="location-points.png"
              className="location_points2_img"
            />
            <img
              src={location}
              alt="location-points.png"
              className="location_points2_img"
            />
          </div>

          <div className="col-lg-3 col-md-3 col-3 mt-3">
            <p className="inputs_locations">Broward-county</p>
            <p className="inputs_locations2">Cutler Bay</p>
            <p className="inputs_locations2">Broward-county</p>
            <p className="inputs_locations2">Cutler Bay</p>
            <p className="inputs_locations2">Broward-county</p>
            <p className="inputs_locations2">Cutler Bay</p>
            <p className="inputs_locations2">Broward-county</p>
          </div>

          <div className="col-lg-1 col-md-1 col-1 mt-3">
            <img
              src={location}
              alt="location-points.png"
              className="location_points_img"
            />
            <img
              src={location}
              alt="location-points.png"
              className="location_points2_img"
            />
            <img
              src={location}
              alt="location-points.png"
              className="location_points2_img"
            />
            <img
              src={location}
              alt="location-points.png"
              className="location_points2_img"
            />
            <img
              src={location}
              alt="location-points.png"
              className="location_points2_img"
            />
            <img
              src={location}
              alt="location-points.png"
              className="location_points2_img"
            />
            <img
              src={location}
              alt="location-points.png"
              className="location_points2_img"
            />
          </div>

          <div className="col-lg-3 col-md-3 col-3 mt-3">
            <p className="inputs_locations">Broward-county</p>
            <p className="inputs_locations2">Cutler Bay</p>
            <p className="inputs_locations2">Broward-county</p>
            <p className="inputs_locations2">Cutler Bay</p>
            <p className="inputs_locations2">Broward-county</p>
            <p className="inputs_locations2">Cutler Bay</p>
            <p className="inputs_locations2">Broward-county</p>
          </div>
{/* <div className="content_style"> */}
          <div className="col-lg-1 col-md-1 col-1 mt-3">
            <img
              src={location}
              alt="location-points.png"
              className="location_points_img"
            />
            <img
              src={location}
              alt="location-points.png"
              className="location_points2_img"
            />
            <img
              src={location}
              alt="location-points.png"
              className="location_points2_img"
            />
            <img
              src={location}
              alt="location-points.png"
              className="location_points2_img"
            />
            <img
              src={location}
              alt="location-points.png"
              className="location_points2_img"
            />
            <img
              src={location}
              alt="location-points.png"
              className="location_points2_img"
            />
            <img
              src={location}
              alt="location-points.png"
              className="location_points2_img"
            />
          </div>
            
            <div className="col-lg-3 col-md-3 col-3 mt-3">
            <p className="inputs_locations">Broward-county</p>
            <p className="inputs_locations2">Cutler Bay</p>
            <p className="inputs_locations2">Broward-county</p>
            <p className="inputs_locations2">Cutler Bay</p>
            <p className="inputs_locations2">Broward-county</p>
            <p className="inputs_locations2">Cutler Bay</p>
            <p className="inputs_locations2">Broward-county</p>
          </div>
 {/* </div> */}

        </div>
      </div>
    </div>
    </div>
  )
}

export default Pinlocations

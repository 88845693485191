import "./App.css";
import Navbar from "./Camponents/Navbar/Navbar";

import Mergallcamponents from "./Camponents/Mergallcamponents/Mergallcamponents";
function App() {
  return (
    <>
      {/* <Navbar /> */}
     
      <Mergallcamponents/>
    </>
  );
}

export default App;

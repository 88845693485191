import React from "react";
import "./../Sellyourhouse/Sellyourhouse.css";
function Sellyourhouse() {
  return (
    <div className="main_div3">
      <div className="background_img_sellyourhouse">
        <div className="heading_house">
          Sell Your Houston House On Your Terms.
        </div>
        <p className="paragraf_house">
          Sell your home to Houston House Buyers and avoid the hassle of
          repairs,listing your house with an agent, fickle buyers, and months of
          uncertainty.
        </p>

        <div className="helped_heading">
          We’ve helped homeowners in the following situations:
        </div>

        <div className="row">
          <div className="col-lg-1 col-md-1 col-1" />

          <div className="col-lg-2 col-md-2 col-2">
            <div className="points_box mt-4">
              <div className="inputs_name_sellhouse" title="Foreclosure">
                Foreclosure
              </div>
            </div>

            <div className="points_box ">
              <div className="inputs_name_sellhouse" title="Relocating">
                Relocating
              </div>
            </div>

            <div className="points_box ">
              <div className="inputs_name_sellhouse" title="Probate">
                Probate
              </div>
            </div>
          </div>

          <div className="col-lg-2 col-md-2 col-2">
            <div className="points_box mt-4">
              <div className="inputs_name_sellhouse" title="Tax Lien">
                Tax Lien
              </div>
            </div>

            <div className="points_box ">
              <div className="inputs_name_sellhouse" title="Inherite Home">
                Inherite...
              </div>
            </div>

            <div className="points_box ">
              <div className="inputs_name_sellhouse" title="Vacant Home">
                Vacant...
              </div>
            </div>
          </div>

          <div className="col-lg-2 col-md-2 col-2">
            <div className="points_box mt-4">
              <div className="inputs_name_sellhouse" title="Divorce">
                Divorce
              </div>
            </div>

            <div className="points_box ">
              <div className="inputs_name_sellhouse" title="Behind On Payments">
                Behind...
              </div>
            </div>

            <div className="points_box ">
              <div className="inputs_name_sellhouse" title="Job Loss">
                Job Loss
              </div>
            </div>
          </div>

          <div className="col-lg-2 col-md-2 col-2">
            <div className="points_box mt-4">
              <div className="inputs_name_sellhouse" title="Expired Listing">
                Expired...
              </div>
            </div>

            <div className="points_box ">
              <div className="inputs_name_sellhouse" title="Fire Damage">
                Fire...
              </div>
            </div>

            <div className="points_box ">
              <div className="inputs_name_sellhouse" title="Mold">
                Mold
              </div>
            </div>
          </div>

          <div className="col-lg-2 col-md-2 col-2">
            <div className="points_box mt-4">
              <div className="inputs_name_sellhouse" title="Code Violations">
                Code...
              </div>
            </div>

            <div className="points_box ">
              <div className="inputs_name_sellhouse" title="Job Transfer">
                Job...
              </div>
            </div>

            <div className="points_box ">
              <div
                className="inputs_name_sellhouse"
                title="Landlords with Problems Tenants"
              >
                Landlords...
              </div>
            </div>
          </div>
        </div>

        <p className="fill_out_input">Fill out the short form…</p>
        <div className="parsonal_information_section">
          <p className="property_address">
            Property Address*
            <input className="property_input" />
          </p>

          <div className="row">
            <div className="col-lg-3 col-md-3 col-3">
              <div className="property_name">
                Name
                <input className="name_input" />
              </div>
            </div>

            <div className="col-lg-3 col-md-3 col-3">
              <div className="property_name">
                Phone
                <input className="name_input" />
              </div>
            </div>

            <div className="col-lg-6 col-md-6 col-6">
              <div className="property_email">
                Email*
                <input className="email_input" />
              </div>
            </div>
          </div>

          <div className="get_cash_button">
          <p className="get_my_fair">Get My Fair Cash Offer</p></div>
        </div>
      </div>
    </div>
  );
}

export default Sellyourhouse;
